import React, { useState } from 'react';
import { BsQuestionCircleFill } from 'react-icons/bs';

const faqs = [
  {
    question: 'What is Matka Satta?',
    answer: 'Matka Satta is a popular online lottery game in India where players bet on numbers and try to guess the winning number combination.',
  },
  {
    question: 'How do I place a bet?',
    answer: 'You can place a bet by selecting your preferred betting option and entering the amount. Once confirmed, your bet will be placed.',
  },
  {
    question: 'Is it safe to play?',
    answer: 'Yes, Matka Satta uses secure and encrypted payment gateways to ensure that all transactions are safe and secure.',
  },
  {
    question: 'What is Navratan Market?',
    answer: 'Navratan Market is another popular online lottery game similar to Matka Satta, where players bet on numbers to win.',
  },
  {
    question: 'How can I withdraw my winnings?',
    answer: 'To withdraw your winnings, navigate to the withdrawal section, enter the amount you wish to withdraw, and follow the instructions provided.',
  },
  {
    question: 'Are there any bonuses or promotions available?',
    answer: 'Yes, Matka Satta and Navratan Market offer various bonuses and promotions to players, including welcome bonuses, referral bonuses, and special promotions during festivals.',
  },
  {
    question: 'What are the payment methods available?',
    answer: 'You can use various payment methods such as credit/debit cards, net banking, and digital wallets to deposit funds for playing Matka Satta and Navratan Market.',
  },
  {
    question: 'How do I contact customer support?',
    answer: 'For any queries or issues, you can contact our customer support team via email at support@matkasatta.com or through our live chat feature on the website.',
  },
];

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAnswer = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="bg-white p-8 rounded-lg shadow-lg">
      <h2 className="text-3xl font-semibold text-center text-gray-800 mb-6">Frequently Asked Questions</h2>
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <div key={index} className="border border-gray-200 rounded-lg overflow-hidden">
            <button
              className="flex items-center justify-between w-full p-4 focus:outline-none"
              onClick={() => toggleAnswer(index)}
            >
              <div className="flex items-center">
                <BsQuestionCircleFill className="text-blue-500 mr-2 h-6 w-6" />
                <span className="text-lg font-medium text-gray-800">{faq.question}</span>
              </div>
              <svg
                className={`h-6 w-6 ${openIndex === index ? 'transform rotate-180' : ''}`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            {openIndex === index && (
              <div className="p-4 bg-gray-50">
                <p className="text-gray-700">{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
