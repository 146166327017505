import React from 'react';

const features = [
  { icon: '🎲', title: 'Multiple Betting Options', description: 'Single, Jodi, Patti/Panna, Half Sangam, Full Sangam.' },
  { icon: '⏰', title: 'Scheduled Draws', description: 'Draws are scheduled at different times throughout the day with specific opening and closing times for placing bets.' },
  { icon: '📱', title: 'User-Friendly Interface', description: 'Easy navigation for placing bets with real-time updates on bets and results.' },
  { icon: '🔒', title: 'Secure Transactions', description: 'Multiple payment options with secure and encrypted payment gateway.' },
  { icon: '📊', title: 'Live Results', description: 'Results are updated live on the platform with notifications sent to users.' },
  { icon: '🎁', title: 'Bonus and Rewards', description: 'Daily bonuses for regular players, referral bonuses, and special rewards during festivals and events.' },
];

const Features = () => {
  return (
    <div id="features" className="bg-white p-8 rounded-2xl shadow-2xl">
      <h2 className="text-4xl font-bold text-center mb-8 text-gray-800">Game Features</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {features.map((feature, index) => (
          <Feature key={index} icon={feature.icon} title={feature.title} description={feature.description} />
        ))}
      </div>
    </div>
  );
};

const Feature = ({ icon, title, description }) => (
  <div className="flex items-start space-x-4">
    <div className="text-4xl">{icon}</div>
    <div>
      <h3 className="text-2xl font-semibold text-gray-800 mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

export default Features;
