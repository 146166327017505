import React from 'react';
import { AiOutlineNumber, AiOutlineCluster, AiOutlineBorder, AiOutlineDatabase, AiOutlineProfile, AiOutlineGold, AiOutlineFieldTime, AiOutlineStock, AiOutlineCheckCircle } from 'react-icons/ai'; // Correct import path

const GameRates = () => {
  // Data for game rates
  const rates = [
    { category: 'Single Digit', rate: '9.5 Rs', icon: <AiOutlineNumber /> },
    { category: 'Jodi Digits', rate: '95 Rs', icon: <AiOutlineCluster /> },
    { category: 'Red Brackets', rate: '95 Rs', icon: <AiOutlineBorder /> },
    { category: 'Single Pana', rate: '150 Rs', icon: <AiOutlineDatabase /> },
    { category: 'Double Pana', rate: '300 Rs', icon: <AiOutlineProfile /> },
    { category: 'Triple Pana', rate: '900 Rs', icon: <AiOutlineGold /> },
    { category: 'Half Sangam A', rate: '1000 Rs', icon: <AiOutlineFieldTime /> },
    { category: 'Half Sangam B', rate: '1000 Rs', icon: <AiOutlineStock /> },
    { category: 'Full Sangam', rate: '10000 Rs', icon: <AiOutlineCheckCircle /> },
  ];

  return (
    <div className="container mx-auto px-5 bg-gradient-to-r from-blue-200 to-blue-100 py-8">
    <h1 className="text-4xl text-center font-bold mb-5">Game Rates</h1>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {rates.map((item, index) => (
        <div
          key={index}
          className="bg-white rounded-lg shadow-md p-5 hover:shadow-lg transition duration-300 relative"
        >
          {index % 3 !== 3 && (
            <div className="absolute left-0 top-0 bottom-0 w-1 bg-yellow-500 rounded-l-lg"></div>
          )}
          <div className="flex items-center mb-4">
            <div className="text-green-500 text-3xl mr-3">{item.icon}</div>
            <h2 className="text-2xl font-semibold">{item.category}</h2>
          </div>
          <div className="flex justify-between items-center mb-2">
            <span className="text-gray-700 font-medium">1 RS KA</span>
            <span className="text-green-500 text-lg font-bold">{item.rate}</span>
          </div>
          <p className="text-sm text-gray-600">{getDescription(item.category)}</p>
        </div>
      ))}
    </div>
  </div>
  
  );
};

// Function to provide description for each category
function getDescription(category) {
  switch (category) {
    case 'Single Digit':
      return 'Bet on a single digit number.';
    case 'Jodi Digits':
      return 'Bet on two-digit combinations.';
    case 'Red Brackets':
      return 'Bet on red brackets.';
    case 'Single Pana':
      return 'Bet on a single pana.';
    case 'Double Pana':
      return 'Bet on a double pana.';
    case 'Triple Pana':
      return 'Bet on a triple pana.';
    case 'Half Sangam A':
      return 'Bet on half sangam A.';
    case 'Half Sangam B':
      return 'Bet on half sangam B.';
    case 'Full Sangam':
      return 'Bet on full sangam.';
    default:
      return '';
  }
}

export default GameRates;
