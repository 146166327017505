import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiMail, FiPhone, FiMapPin, FiInfo, FiHelpCircle, FiUsers, FiYoutube, FiTelegram, FiLinkedin } from 'react-icons/fi';
import { SiApple, SiGoogleplay } from 'react-icons/si';
import { HiOutlineExternalLink } from 'react-icons/hi';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h2 className="text-2xl font-bold mb-4">Quick Links</h2>
            <ul className="space-y-4">
              <li><a href="#about" className="flex items-center hover:text-gray-300"><FiInfo className="mr-2" /> About Navratan Market</a></li>
              <li><a href="#how-to-play" className="flex items-center hover:text-gray-300"><FiHelpCircle className="mr-2" /> How to Play</a></li>
              <li><a href="#why-choose-us" className="flex items-center hover:text-gray-300"><FiUsers className="mr-2" /> Why Choose Us</a></li>
              <li><a href="#faq" className="flex items-center hover:text-gray-300"><FiHelpCircle className="mr-2" /> FAQ</a></li>
              <li><a href="#contact" className="flex items-center hover:text-gray-300"><FiMail className="mr-2" /> Contact</a></li>
            </ul>
          </div>
          <div>
            <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
            <ul className="space-y-4">
              <li><FiMail className="mr-2" /> <a href="mailto:contact@navratan.app">contact@navratan.app</a></li>
              <li><FiPhone className="mr-2" /> <a href="tel:+911234567890">+91 123 456 7890</a></li>
              <li><FiMapPin className="mr-2" /> 1234 Main Street, City, Country</li>
            </ul>
          </div>
          <div>
            <h2 className="text-2xl font-bold mb-4">Follow Us</h2>
            <div className="flex space-x-4">
              <a href="#" className="text-2xl hover:text-gray-300"><FiFacebook /></a>
              <a href="#" className="text-2xl hover:text-gray-300"><FiTwitter /></a>
              <a href="#" className="text-2xl hover:text-gray-300"><FiInstagram /></a>
              <a href="#" className="text-2xl hover:text-gray-300"><FiYoutube /></a>
              <a href="#" className="text-2xl hover:text-gray-300"><FiLinkedin/></a>
            </div>
          </div>
          <div className="flex flex-col space-y-2">
              
            <div className="bg-white text-gray-900 p-2 rounded-lg shadow-lg flex items-center space-x-2">
              <div className="flex items-center justify-center bg-gray-900 rounded-lg p-2">
                <SiGoogleplay className="text-lg text-white hover:text-gray-300 cursor-pointer" />
              </div>
              <div>
                <h2 className="text-base font-bold">Get it on Google Play</h2>
                <p className="text-sm text-gray-600">Download on Google Play for Android devices.</p>
                <a href="#" className="text-xs text-blue-500 hover:underline flex items-center">
                  Learn More <HiOutlineExternalLink className="ml-1 h-4 w-4" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-800 mt-8 pt-8 flex flex-col md:flex-row justify-between items-center">
          <div className="text-lg text-gray-400">
            <p>&copy; {new Date().getFullYear()} Navratan Market. All rights reserved.</p>
          </div>
          <ul className="flex space-x-4 mt-4 md:mt-0">
            <li><a href="#" className="hover:text-gray-300">Terms of Use</a></li>
            <li><a href="#" className="hover:text-gray-300">Privacy Policy</a></li>
            <li><a href="#" className="hover:text-gray-300">Cookie Policy</a></li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
