import React from 'react';

const AdvertisingComponent = () => {
    return (
        <div className="bg-gradient-to-br from-purple-500 to-indigo-600 p-4 rounded-lg shadow-lg text-white">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="flex flex-col justify-center items-center">
                    <h2 className="text-3xl font-bold mb-4">Join the Excitement!</h2>
                    <p className="text-lg mb-6">Experience thrilling lottery games anytime, anywhere.</p>
                    <ul className="space-y-2">
                        <li className="flex items-center space-x-2">
                            <svg className="w-6 h-6 text-yellow-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                            </svg>
                            <span>Multiple betting options including Single, Jodi, and more.</span>
                        </li>
                        <li className="flex items-center space-x-2">
                            <svg className="w-6 h-6 text-yellow-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                            </svg>
                            <span>Scheduled draws with live results.</span>
                        </li>
                        <li className="flex items-center space-x-2">
                            <svg className="w-6 h-6 text-yellow-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                            </svg>
                            <span>User-friendly interface with real-time updates.</span>
                        </li>
                        <li className="flex items-center space-x-2">
                            <svg className="w-6 h-6 text-yellow-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                            </svg>
                            <span>Secure transactions and payment options.</span>
                        </li>
                        <li className="flex items-center space-x-2">
                            <svg className="w-6 h-6 text-yellow-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                            </svg>
                            <span>Daily bonuses and rewards.</span>
                        </li>
                    </ul>
                </div>
                <div className="flex justify-center items-center">
                    <img src='/img/satta.jpeg' alt="Lottery Image" className="rounded-lg shadow-lg md:max-w-full md:h-full" />
                </div>
            </div>
           
        </div>
    );
}

export default AdvertisingComponent;
