import React from 'react';
import { FiShield, FiHeadphones, FiLayers, FiRefreshCcw } from 'react-icons/fi';
import { LuCircleDot } from "react-icons/lu";

const About = () => {
  return (
    <div id="about" className="bg-gradient-to-b from-gray-200 to-white py-12 px-6 lg:px-24">
      <div className="max-w-7xl mx-auto">
        {/* Main Content Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">

          {/* Left Column: Text Content */}
          <div className="flex flex-col justify-center space-y-8">

            <div className="p-4 bg-blue-100 rounded-lg shadow-lg">
              <h3 className="text-4xl font-extrabold mb-4 text-center text-gray-800">How to Play</h3>
              <ul className="text-lg  leading-8 space-y-4">
                <li className="flex items-start">
                  <LuCircleDot className="text-blue-900 mr-2 mt-2" />
                  Choose your lucky numbers or bets based on the available options.
                </li>
                <li className="flex items-start">
                  <LuCircleDot className="text-blue-900 mr-2 mt-2" />
                  Place your bet amount using the secure payment options provided.
                </li>
                <li className="flex items-start">
                  <LuCircleDot className="text-blue-900 mr-2 mt-2" />
                  Wait for the results to be announced live.
                </li>
                
                <li className="flex items-start">
                  <LuCircleDot className="text-blue-900 mr-2 mt-2" />
                  If your numbers match, you win based on the predefined rules.
                </li>
              </ul>
            </div>
          </div>

          {/* Right Column: Video Embed */}
          <div className="flex justify-center border-4 border-blue-600 bg-white p-1">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/h4--KRVTMpo?si=qSjFI75Kh5YBiZqs" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>

        </div>

        {/* Why Choose Us Section */}
        <div className="mt-16 bg-white rounded-xl shadow-lg p-8">
          <h3 className="text-3xl font-bold text-gray-900 mb-8 text-center">Why Choose Navratan Market ?</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex items-center justify-center p-6 bg-gray-100 rounded-xl shadow-md hover:shadow-lg transition duration-300">
              <FiShield className="h-16 w-16 text-purple-600" />
              <div className="ml-4">
                <h4 className="text-xl font-semibold text-gray-900 mb-2">Secure Transactions</h4>
                <p className="text-gray-800">We ensure your payments and personal information are safe with advanced security measures.</p>
              </div>
            </div>
            <div className="flex items-center justify-center p-6 bg-gray-100 rounded-xl shadow-md hover:shadow-lg transition duration-300">
              <FiHeadphones className="h-16 w-16 text-blue-600" />
              <div className="ml-4">
                <h4 className="text-xl font-semibold text-gray-900 mb-2">24/7 Customer Support</h4>
                <p className="text-gray-800">Our dedicated support team is available round the clock to assist you with any queries.</p>
              </div>
            </div>
            <div className="flex items-center justify-center p-6 bg-gray-100 rounded-xl shadow-md hover:shadow-lg transition duration-300">
              <FiLayers className="h-16 w-16 text-green-600" />
              <div className="ml-4">
                <h4 className="text-xl font-semibold text-gray-900 mb-2">Wide Range of Games</h4>
                <p className="text-gray-800">Enjoy a diverse selection of betting options and games to suit your preferences.</p>
              </div>
            </div>
            <div className="flex items-center justify-center p-6 bg-gray-100 rounded-xl shadow-md hover:shadow-lg transition duration-300">
              <FiRefreshCcw className="h-16 w-16 text-red-600" />
              <div className="ml-4">
                <h4 className="text-xl font-semibold text-gray-900 mb-2">Live Result Updates</h4>
                <p className="text-gray-800">Get real-time updates on game results and winnings instantly.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
