

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { FaGamepad, FaClock, FaUsers } from 'react-icons/fa'; // Example icons

const HomePage = () => {
  const [games, setGames] = useState([]);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJzdXBlcmFkbWlucGFyZWV0bWFya2V0QCM3NSIsImV4cGlyZXNJbiI6IjFkIiwiaWF0IjoxNzE5MTk3NDIwLCJleHAiOjE3MjE3ODk0MjB9.vcbaMLj7_-R4hplFJ0Ha2uMfrcAA8c-AIAsXdgkXWvg';
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        };

        const response = await axios.get('https://api.klubbl.in/panaboard/boards', config);
        const allGames = response.data.data;
        const excludedIds = [
          215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230,
          231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 244, 245, 246, 247,
          248, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262, 263,
          264, 265, 266, 267, 268, 269, 270, 271
        ];

        const filteredGames = allGames.filter(game => !excludedIds.includes(game.id));
        setGames(filteredGames);
      } catch (error) {
        console.error('Error fetching games:', error);
        // Optionally, set error state to provide feedback to the user
      }
    };

    fetchData();
  }, []);

  return (
    <div className="w-full bg-gradient-to-r from-purple-200 p-2 via-blue-900">
      <header className="text-center mb-8">
      <motion.h1
      initial={{ y: -100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        duration: 0.6,
        delay: 0.2,
        type: "spring",
        stiffness: 100,
        damping: 10,
      }}
      className="text-4xl font-bold bg-gray-300 text-gray-900 shadow-lg leading-6 px-6 py-3 mt-4 rounded-lg inline-block"
    >
      Games
    </motion.h1>
      </header>
      <section className="max-w-6xl mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {games.map((game) => (
            <motion.div
              key={game.id}
              whileHover={{ scale: 1.05 }}
              className="bg-white rounded-lg shadow-xl overflow-hidden"
            >
              <div className="relative overflow-hidden">
                <img
                  className="w-full h-10 object-cover object-center"
                />
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 0.8 }}
                  transition={{ duration: 0.3 }}
                  className="absolute inset-0 bg-black"
                />
                <motion.div
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                  className="absolute inset-0 flex items-center justify-center text-white text-lg font-semibold"
                >
                  <img src='/img/chip.png' className='w-6 h-6 mr-2' />


                  {game.name}

                </motion.div>
              </div>
              <div className="p-4">
                <p className="text-gray-600 mb-4">{game.description}</p>
                <div className="flex items-center justify-between text-gray-600">
                  <div className="flex items-center">
                    <img src='/img/poker-cards.png' className='w-10 h-10 mr-2' />
                    <span className="text-sm">***_**_***</span>
                  </div>
                  <motion.button
                    whileHover={{ scale: 1.05, boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)' }}
                    whileTap={{ scale: 0.95 }}
                    className="bg-gradient-to-t flex from-blue-400 to-blue-800 text-white py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
                  >
                    <img src='/img/play.png' className='w-5 h-5 mr-1 mt-1' />

                    Join Game
                  </motion.button>
                </div>
              </div>
              <div className="bg-gray-300 p-4 flex justify-between items-center">
                <div className="flex items-center">

                  <FaUsers className="text-blue-500 mr-2" />
                  <p className="text-xs font-semibold">Open Bids: {game.openBids}</p>
                </div>
                <div className="flex items-center">
                  <FaClock className="text-blue-500 mr-2" />
                  <p className="text-xs font-semibold">Close Bids: {game.closeBids}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default HomePage;
