import React from 'react';
import DownloadButton from './DownloadButton';
import HeroSection from './HeroSection';
import Features from './Features';
import About from './About';
import Team from './Games';
import Testimonials from './Testimonials';
import FAQ from './FAQ';
import ContactForm from './ContactForm';
import Footer from './Footer';
import AdvertisingComponent from './AdvertisingComponent';
import GameRates from './GameRates';

const DownloadPage = () => {
  return (
    <>
      <HeroSection />

      <div className="min-h-screen flex flex-col items-center bg-gray-100 justify-center">
        <section id="advertising-component" className="w-full max-w-6xl my-8">
          <AdvertisingComponent />
        </section>
        <GameRates/>
        <Team />
       
        <section className="w-full max-w-6xl my-4">
        <About />

        </section>
        <section className="w-full max-w-6xl my-2">
          <Features />
        </section>
        <section className="w-full max-w-6xl my-2">
         
        </section>
        <section className="w-full max-w-6xl my-2">
          <Testimonials />
        </section>
        <section className="w-full max-w-6xl my-2">
          <FAQ />
        </section>
      </div>
      <Footer />
      <br></br>
      <br></br>
      <DownloadButton />
    </>
  );
};

export default DownloadPage;
