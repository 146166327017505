import React, { useState } from 'react';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
  };

  return (
    <div className="bg-white p-8 rounded-2xl shadow-2xl">
      <h2 className="text-4xl font-bold text-center mb-8 text-gray-800">Contact Us</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-lg font-semibold text-gray-700 mb-2">Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full p-3 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-purple-600"
            required
          />
        </div>
        <div>
          <label className="block text-lg font-semibold text-gray-700 mb-2">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-3 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-purple-600"
            required
          />
        </div>
        <div>
          <label className="block text-lg font-semibold text-gray-700 mb-2">Message</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="w-full p-3 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-purple-600"
            required
          ></textarea>
        </div>
        <button
          type="submit"
          className="w-full bg-purple-600 text-white py-3 rounded-lg shadow-lg hover:bg-purple-700"
        >
          Send Message
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
