import React from 'react';
import { FiStar } from 'react-icons/fi'; // Importing star icon from react-icons/fi
import { BsChatQuote } from 'react-icons/bs'; // Importing quote icon from react-icons/bs
import { GrUserManager } from 'react-icons/gr'; // Importing user manager icon from react-icons/gr

const testimonials = [
  {
    name: 'Rajesh',
    role: 'Online Gamer',
    feedback: 'Navratan Market is the best online lottery game I have ever played. The interface is user-friendly and the transactions are secure.',
  },
  {
    name: 'Shankar',
    role: 'Lottery Enthusiast',
    feedback: 'I love the daily bonuses and rewards. The live results feature keeps me updated all the time.',
  },
  {
    name: 'Laxmi',
    role: 'Betting Expert',
    feedback: 'The multiple betting options make the game very exciting. I highly recommend Navratan Market to all lottery enthusiasts.',
  },
  {
    name: 'Suranjana',
    role: 'Customer Support Specialist',
    feedback: 'Amazing customer support and quick withdrawals. Navratan Market has made online gambling enjoyable and hassle-free for me.',
  },
];

const reviews = [
  {
    name: 'Mohit',
    role: 'Web Developer',
    rating: 4.5,
    review: 'The website design is sleek and intuitive. It’s easy to navigate and place bets. I’m impressed with the overall experience.',
  },
  {
    name: 'Sanjay',
    role: 'Graphic Designer',
    rating: 4.2,
    review: 'Navratan Market provides a great platform for online lottery enthusiasts. The UI is clean and payments are processed quickly.',
  },
  {
    name: 'Debashish',
    role: 'Marketing Specialist',
    rating: 4.8,
    review: 'As someone new to online betting, Navratan Market has exceeded my expectations. The customer service is exceptional and the rewards are generous.',
  },
  {
    name: 'Karan',
    role: 'Student',
    rating: 4.0,
    review: 'I enjoy the variety of games offered on Navratan Market. It’s a fun and reliable platform for anyone interested in online gambling.',
  },
];

const Testimonials = () => {
  return (
    <div className="bg-gray-100 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <h2 className="text-4xl font-bold text-center text-gray-800 py-8">What Users Say</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8 px-6 lg:px-12 pb-8">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="p-6 bg-white rounded-lg shadow-md">
                <div className="flex items-center mb-4">
                  <BsChatQuote className="text-4xl text-blue-500 mr-4" />
                  <div>
                    <p className="text-lg text-gray-800 leading-relaxed">{testimonial.feedback}</p>
                    <h4 className="text-lg font-semibold text-gray-900">{testimonial.name}</h4>
                    <p className="text-sm text-gray-600">{testimonial.role}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-lg mt-8 overflow-hidden">
          <h2 className="text-4xl font-bold text-center text-gray-800 py-8">Reviews</h2>
          {reviews.map((review, index) => (
            <div key={index} className="p-6 bg-white rounded-lg shadow-md mb-8">
              <div className="flex items-center mb-4">
                <GrUserManager className="text-4xl text-blue-500 mr-4" />
                <div>
                  <p className="text-lg text-gray-800 leading-relaxed">{review.review}</p>
                  <div className="flex items-center mt-2">
                    {[...Array(5)].map((_, i) => (
                      <FiStar key={i} className={`text-xl ${i < review.rating ? 'text-yellow-500' : 'text-gray-300'}`} />
                    ))}
                    <span className="ml-2 text-gray-600">{review.rating.toFixed(1)}</span>
                  </div>
                  <h4 className="text-lg font-semibold text-gray-900">{review.name}</h4>
                  <p className="text-sm text-gray-600">{review.role}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
