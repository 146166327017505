import React, { useState, useEffect } from 'react';
import { FaDownload } from 'react-icons/fa';

const DownloadButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const advertisingComponent = document.getElementById('advertising-component');
      const downloadButton = document.getElementById('download-button');
      
      if (advertisingComponent && downloadButton) {
        const rect = advertisingComponent.getBoundingClientRect();
        if (rect.bottom < window.innerHeight) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleDownload = () => {
    setIsDownloading(true);
    
    // Simulating a download delay for demonstration purposes
    setTimeout(() => {
      setIsDownloading(false);

      // Trigger the file download
      const fileUrl = "https://klubblstorage.s3.ap-south-1.amazonaws.com/banners/navratan.apk";
      const a = document.createElement('a');
      a.href = fileUrl;
      a.download = 'navratan777.apk'; // You can specify the filename here
      a.click(); // Trigger the download
    }, 2000); // 2 seconds delay
  };

  return (
    <div
      id="download-button"
      className={`fixed bottom-2 left-0 right-0 mx-auto w-full md:w-full lg:w-full transition-opacity duration-500 transform ${
        isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
      }`}
    >
      <button
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleDownload}
        disabled={isDownloading}
        className={`w-full flex items-center justify-center px-6 py-3 text-white font-semibold rounded-lg bg-gradient-to-r ${
          isHovered
            ? 'from-yellow-500 to-red-500 hover:from-yellow-600 hover:to-red-600'
            : 'from-blue-500 to-green-500 hover:from-blue-600 hover:to-green-600'
        } shadow-lg relative`}
        style={{
          perspective: '1000px',
          transformStyle: 'preserve-3d',
          transition: 'transform 0.3s',
        }}
      >
        {isDownloading ? (
          <div className="flex items-center justify-center space-x-2">
            <span className="text-lg">Downloading...</span>
            <div className="animate-spin h-5 w-5 border-b-2 border-white rounded-full"></div>
          </div>
        ) : (
          <>
            <FaDownload className={`mr-3 text-lg`} />
            <span className="text-2xl bg-clip-text text-white animate-pulse">
              {isHovered ? 'Download Now!' : 'Get the App Now'}
            </span>
            {isHovered && <div className="absolute top-0 right-0 h-4 w-4 bg-purple-500 rounded-full animate-ping"></div>}
          </>
        )}
      </button>
    </div>
  );
};

export default DownloadButton;
