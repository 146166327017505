import React from 'react';
import { Link } from 'react-router-dom';
import { FaGamepad, FaCoins, FaClock, FaUsers } from 'react-icons/fa';
import { FaRocket, FaInfoCircle } from 'react-icons/fa';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './tailwind.css'; // Import your Tailwind CSS file
import { RiHome2Line, RiInformationLine, RiTeamLine, RiStarLine, RiQuestionLine, RiContactsLine } from 'react-icons/ri';

const HeaderSection = () => {
  const handleDownload = () => {
    
    // Simulating a download delay for demonstration purposes
    setTimeout(() => {
      // Trigger the file download
      const fileUrl = "https://klubblstorage.s3.ap-south-1.amazonaws.com/banners/navratan.apk";
      const a = document.createElement('a');
      a.href = fileUrl;
      a.download = 'navratan777.apk'; // You can specify the filename here
      a.click(); // Trigger the download
    }, 2000); // 2 seconds delay
  };
  return (
    <div className="mx-auto">
      {/* Navigation Bar */}
      <nav className="w-full bg-gradient-to-r from-purple-700 via-blue-700 to-pink-700 text-white py-4 shadow-lg fixed top-0 z-50">
        <div className="container mx-auto flex justify-between items-center px-4">
          {/* Logo and Branding */}
          <div className="flex items-center space-x-3">
            <div className="rounded-full overflow-hidden bg-whites p-1">
              <img src="navratan.png" alt="Logo" className="h-10 w-10 object-cover animate-pulse filter brightness- -10" />
            </div>
            <h1 className="text-lg font-semibold text-white">Navratan777</h1>
          </div>

          {/* Navigation Links */}
          <ul className="flex space-x-4">
            <li>
              <Link to="/features" className="nav-link flex items-center space-x-2 hover:text-yellow-400 transition-colors duration-300">
                <RiHome2Line className="nav-icon text-yellow-400" />
                <span className="hidden md:inline-block">Features</span>
              </Link>
            </li>
            <li>
              <Link to="/about" className="nav-link flex items-center space-x-2 hover:text-blue-400 transition-colors duration-300">
                <RiInformationLine className="nav-icon text-blue-400" />
                <span className="hidden md:inline-block">About</span>
              </Link>
            </li>
            <li>
              <Link to="/team" className="nav-link flex items-center space-x-2 hover:text-purple-400 transition-colors duration-300">
                <RiTeamLine className="nav-icon text-purple-400" />
                <span className="hidden md:inline-block">Our Team</span>
              </Link>
            </li>
            <li>
              <Link to="/testimonials" className="nav-link flex items-center space-x-2 hover:text-red-400 transition-colors duration-300">
                <RiStarLine className="nav-icon text-red-400" />
                <span className="hidden md:inline-block">Testimonials</span>
              </Link>
            </li>
            <li>
              <Link to="/faq" className="nav-link flex items-center space-x-2 hover:text-green-400 transition-colors duration-300">
                <RiQuestionLine className="nav-icon text-green-400" />
                <span className="hidden md:inline-block">FAQ</span>
              </Link>
            </li>
            <li>
              <Link to="/contact" className="nav-link flex items-center space-x-2 hover:text-blue-400 transition-colors duration-300">
                <RiContactsLine className="nav-icon text-blue-400" />
                <span className="hidden md:inline-block">Contact Us</span>
              </Link>
            </li>
          </ul>
        </div>
      </nav>

      {/* Hero Section */}
      <section className="relative h-screen bg-gradient-to-r from-purple-800 via-blue-900 to-black text-white overflow-hidden">
        {/* Overlay */}
        <div className="absolute inset-0 bg-opacity-70 bg-black"></div>
        {/* Visual Effects */}
        <div className="absolute inset-0 z-0 overflow-hidden">
          <div className="absolute inset-0 bg-stars"></div>
        </div>
        <div className="relative z-10 flex flex-col items-center justify-center h-full max-w-6xl mx-auto px-6">
          <div className="text-center">
            <h1 className="text-6xl font-extrabold mb-4 animate-bounce text-yellow-300">Welcome to Navratan777</h1>
            <p className="text-2xl mb-8 leading-relaxed text-yellow-300">
              Dive into the world of excitement and thrill with the most popular lottery game. Discover your luck and win big!
            </p>
            <div className="flex justify-center space-x-6 mb-12">
              <button onClick={handleDownload} className="px-8 py-4 bg-yellow-300 text-black font-semibold rounded-full shadow-lg hover:bg-yellow-400 transform hover:scale-105 transition duration-300">Download Now</button>
              </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
            <div className="flex flex-col items-center text-center text-yellow-300">
              <FaRocket className="text-5xl mb-2 animate-bounce" />
              <p className="text-lg font-semibold">Fast Payouts</p>
            </div>
            <div className="flex flex-col items-center text-center text-yellow-300">
              <FaGamepad className="text-5xl mb-2 animate-spin" />
              <p className="text-lg font-semibold">Exciting Games</p>
            </div>
            <div className="flex flex-col items-center text-center text-yellow-300">
              <FaInfoCircle className="text-5xl mb-2 animate-pulse" />
              <p className="text-lg font-semibold">24/7 Support</p>
            </div>
          </div>
          <Carousel
            autoPlay
            infiniteLoop
            showStatus={false}
            showThumbs={false} // Disable thumbs (bubbles)
            showArrows={true}
            interval={4000}
            className="max-w-4xl w-full"
          >
            <div className="relative h-full">
              <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-80"></div>
              <div className="absolute inset-0 flex flex-col items-center justify-center text-center">
                <h2 className="text-3xl mb-2 font-extrabold">Fast Payouts</h2>
                <p className="text-lg">Discover fast payouts and secure transactions.</p>
              </div>
            </div>
            <div className="relative h-full">
              <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-80"></div>
              <div className="absolute inset-0 flex flex-col items-center justify-center text-center">
                <h2 className="text-3xl mb-2 font-extrabold">Exciting Games</h2>
                <p className="text-lg">Enjoy thrilling games with high rewards.</p>
              </div>
            </div>
            <div className="relative h-full">
              <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-80"></div>
              <div className="absolute inset-0 flex flex-col items-center justify-center text-center">
                <h2 className="text-3xl mb-2 font-extrabold">24/7 Support</h2>
                <p className="text-lg">Get 24/7 customer support for all your inquiries.</p>
              </div>
            </div>
          </Carousel>
        </div>
        <div className="absolute bottom-0 left-0 right-0 h-24 bg-white bg-opacity-10 backdrop-blur-md rounded-t-3xl flex items-center justify-center">
          <p className="text-2xl font-semibold text-yellow-300">Experience the thrill of Matka Satta like never before!</p>
        </div>
      </section>
    </div>
  );
};

export default HeaderSection;
