import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Downloading from './components/Downloading';
import Features from './components/Features';
import About from './components/About';
import ContactForm from './components/ContactForm';
import GameRates from './components/GameRates';
import Testimonials from './components/Testimonials';
import FAQ from './components/FAQ';



const App = () => {
 
  return (
    <>
   <Router>
     
      <Routes>
        <Route path='/'element={<Downloading/>}/>
        <Route path='/features'element={<Features/>}/>
        <Route path='/about'element={<About/>}/>
        <Route path='/contact'element={<ContactForm/>}/>
        <Route path='/testimonials'element={<Testimonials/>}/>
        <Route path='/faq'element={<FAQ/>}/>
        <Route path='/games'element={<GameRates/>}/>
        
        
        
      </Routes>
    </Router>
    </>
  );
};

export default App;
